'use strict';

import * as nav from "./checkout-navigation.js";
import * as cartApi from "./checkout-cart-api.js";
import * as step0 from "./checkout-step-0.js";    // Cart
import * as step1 from "./checkout-step-1.js";    // Login / create account
import * as step2 from "./checkout-step-2.js";    // Biller
import * as step3 from "./checkout-step-3.js";    // Attendees
import * as step4 from "./checkout-step-4.js";    // Shipping
import * as step5 from "./checkout-step-5.js";    // Payment
import * as step6 from "./checkout-step-6.js";    // Review
import * as utils from "./utils.js";

const state = {
  debug: true,
  data: {},
  events: [
    'elp:account:login',         // Dispatched just before a login is sent
    'elp:account:logout',        // Dispatched just before a logout is sent
    'elp:account:logged-out',    // Called when a successful logout has happened
    'elp:cart:open',             // When the cart is about to open
    'elp:cart:close',            // When the cart is about to close
	  'elp:login:close',
    'elp:cart:changed',          // Triggered by things like add to cart. Happens before API calls.
    'elp:cart:qty-changed',      // When any quantity has changed
    'elp:cart:fetch',            // When fetch starts
    'elp:cart:fetched',          // When the fetch finishes
    'elp:cart:render',           // When we want to do the actual render
    'elp:cart:rendered',         // When the cart/checkout page has been added to the DOM
    'elp:cart:attendee-changed', // After attendee data has changed
    'elp:cart:coupon-added',     // After a coupon has been applied
    'elp:cart:trueFormLoaded',   // When the *real* checkout form has been loaded from /checkout/
  ],
  eventsThatMakeCheckoutBusy: [
    'elp:account:login',
    'elp:cart:open',
    'elp:cart:changed',
    'elp:cart:qty-changed',
    'elp:cart:fetch',
  ],
  eventsThatMakeCheckoutReady: [
    'elp:cart:rendered',
    'elp:cart:fetched',
  ]
};

// Start here, initialise all the containers

export function init() {

  if (state.debug) {
    window.elpCartState = state;
  }

  state.checkoutContainer = document.querySelector('#checkout');
  state.body = document.querySelector('body');
  state.trueForm = document.querySelector('[co-true-form]');

  if (!state.checkoutContainer) {
    console.error('No #checkout element found.');
    return;
  }

  addEventListeners();

  nav.init(state);
  step0.init(state);  // Cart
  //step1.init(state);  // Login / create account
  //step2.init(state);  // Biller
  //step3.init(state);  // Attendees
  //step4.init(state);  // Shipping
  //step5.init(state);  // Payment
  //step6.init(state);  // Review

  // Load the cart
  utils.dispatchGlobal('elp:cart:fetch');

  // Look to see if we need to skip to a page on the checkout
  const urlParams = new URLSearchParams(window.location.search);
  if ( urlParams.has('elp_checkout_step') ) {
    const pageToJumpTo = parseInt(urlParams.get('elp_checkout_step'));

    //location.search = '';

    //console.log(`Jumping to ${pageToJumpTo}`);
    utils.dispatchGlobal(
      'elp:cart:goto-page',
      {
        page: pageToJumpTo
      }
    );
  }
}

function addEventListeners() {
  // Display event names during debugging
  if (state.debug) {
    state.events.forEach(
      (eventName) => {
        state.body.addEventListener(
          eventName,
          (event) => {
            //console.log(`Event: ${event.type}`);
          },
          { passive: true }
        );
      }
    )
  }

  // Add a listener for fetching the cart
  state.body.addEventListener(
      'elp:cart:fetch',
      cartApi.fetchCart,
      { passive: true }
    )
  ;

  // Save the data to the shared `state` when the cart is fetched
  state.body.addEventListener(
      'elp:cart:fetched',
      (event) => {
        state.data = event.detail.data
      },
      { passive: true }
    )
  ;

  // Do a logout when triggered
  state.body.addEventListener(
      'elp:account:logout',
      (event) => {
        cartApi.logout();
      },
      { passive: true }
    )
  ;

  // When a logout has succeeded, clear the local data
  state.body.addEventListener(
      'elp:account:logged-out',
      (event) => {
        utils.clearStoredData();
        state.data = {};
        utils.dispatchGlobal('elp:cart:close');
      },
      { passive: true }
    )
  ;

  // Whenever any of these events happen, flag the checkout as busy
  state.eventsThatMakeCheckoutBusy.forEach(
    (eventName) => {
      state.body.addEventListener(
          eventName,
          makeCheckoutBusy,
          { passive: true }
        )
      ;
    }
  );

  // Make the cart available after rendering finishes, or the cart has been fetched
  state.eventsThatMakeCheckoutReady.forEach(
    (eventName) => {
      state.body.addEventListener(
          eventName,
          makeCheckoutReady,
          { passive: true }
        )
      ;
    }
  );
}

function makeCheckoutBusy() {
  //console.log(' - Checkout busy');
  state.checkoutContainer.classList.add('loading');
}

function makeCheckoutReady() {
  if (state.hasOwnProperty('makeReadyTimer')) {
    clearTimeout(state.makeReadyTimer);
  }

  state.makeReadyTimer = setTimeout(
    () => {
      //.log(' - Checkout ready!');
      state.checkoutContainer.classList.remove('loading');
    },
    500
  )
}
