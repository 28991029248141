'use strict';
/**
 * Step 3: Attendees (for Open Access and Courses)
 */

import * as utils from './utils.js';
import * as checkoutUtils from "./checkout-utils.js";
import * as nav from "./checkout-navigation.js";
import * as oaUtils from "./open-access-utils.js";

let state = {
  pageId: 3,
  sectionTemplateSelector: '[elp-template="checkout-step-3__section"]',
  subsectionTemplateSelector: '[elp-template="checkout-step-3__subsection"]',
  attendeeTemplateSelector: '[elp-template="checkout-step-3__attendee"]'
}

export function init(globalState) {
  state.globalState = globalState;
  state.template = globalState.checkoutContainer.querySelector('[co-step="3"]');
  state.sectionContainer = state.template.querySelector('[co-attendees-sections-container]');
  addEventListeners();

  // listen for requests to change to this page
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:goto-page',
      (event) => {
        if (event.detail.page === state.pageId) {
          render();
          checkoutUtils.displayActivePage(state.pageId);
        }
      },
      { passive: true }
    )
  ;

  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:fetched',
      render,
      { passive: true }
    )
  ;

  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:attendee-changed',
      render,
      { passive: true }
    )
  ;
}

function render() {
  //console.log(`Rendering #${state.pageId}`);

  renderIntro();
  renderSections();
  utils.dispatchGlobal('elp:cart:rendered');
}

function renderIntro() {
  // "Step [x]"
  state
    .template
    .querySelector('[co-current-checkout-step]')
    .innerText = checkoutUtils.fetchCurrentPageNumber(state.pageId, state.globalState.data)
  ;

  // "of [y]"
  state
    .template
    .querySelector('[co-total-checkout-steps]')
    .innerText = checkoutUtils.totalNumberOfCheckoutSteps(state.globalState.data);
}

function renderSections() {
  let cartItems;
  if (
    !('data' in state.globalState)
    || !('items' in state.globalState.data)
  ) {
    return;
  }

  utils.emptyDomElement(state.sectionContainer);

  let allOpenAccessTypes = oaUtils.fetchAllOpenAccessTypesInCart(state.globalState.data.items);

  allOpenAccessTypes
    .forEach(
      (resourceName) => {
        renderSection(resourceName);
      }
    )
  ;
}

export function fetchAttendeeHtml()
{
  return state.sectionContainer.cloneNode(true);
}

function renderSection(resourceName)
{
  let section = utils.cloneTemplate(state.sectionTemplateSelector);
  section.querySelector('[co-attendees-section-title]').innerText = resourceName;

  // Get all ccart items of this type
  let itemsOfType = oaUtils.fetchOpenAccessCartItemsByResourceId(
    state.globalState.data.items,
    oaUtils.getResourceIdByName(resourceName)
  );

  itemsOfType
    .forEach(
      (itemOfType) => {
        renderSubsection(section, itemOfType);
      }
    )
  ;

  state.sectionContainer.appendChild(section);
}

function renderSubsection(section, itemOfType)
{
  let subsection = utils.cloneTemplate(state.subsectionTemplateSelector);
  renderSubsectionMeta(subsection, itemOfType);
  renderSubsectionAttendees(subsection, itemOfType);

  section.querySelector('[co-attendee-subsection-container]').appendChild(subsection);
}

function renderSubsectionMeta(subsection, itemOfType)
{
  let metaContainer = subsection.querySelector('[co-attendee-section-meta]');

  let dateTag = document.createElement('h4');
  let sessionNameTag = document.createElement('p');
  let sessionTimeTag = document.createElement('p');

  dateTag.innerText = utils.dateToHuman(new Date(itemOfType.cart_item_data.wc_bookings_field_start_date_time));

  switch (utils.isMorningOrAfternoon(itemOfType.cart_item_data.wc_bookings_field_start_date_time)) {
    case 'morning':
      sessionNameTag.innerText = 'Morning Session:';
      sessionTimeTag.innerText = 'From 10:00 to 14:00';
      break;

    case 'afternoon':
      sessionNameTag.innerText = 'Afternoon Session:';
      sessionTimeTag.innerText = 'From 15:00 to 19:00';
      break;
  }

  metaContainer.appendChild(dateTag);
  metaContainer.appendChild(sessionNameTag);
  metaContainer.appendChild(sessionTimeTag);
}

function renderSubsectionAttendees(subsection, itemOfType)
{
  let attendeesContainer = subsection.querySelector('[co-attendee-individuals-container]');
  let numberOfPeople = itemOfType.cart_item_data.wc_bookings_field_persons;

  for(let i=0; i < numberOfPeople; i++) {
    renderSubsectionAttendee(attendeesContainer, itemOfType, i);
  }
}

function renderSubsectionAttendee(attendeesContainer, itemOfType, attendeeIndex)
{
  const attendeeElement = utils.cloneTemplate(state.attendeeTemplateSelector);
  const attendeeData = oaUtils.getAttendeeData(itemOfType.item_key, attendeeIndex);

  // Set the display name
  attendeeElement
    .querySelector('[co-attendee-name]')
    .innerText
    = `${attendeeData.first_name} ${attendeeData.last_name}`
  ;

  // Show / hide the "is biller" label
  if (attendeeData.is_biller) {
    attendeeElement.querySelector('[co-attendee-is-biller]').classList.remove('hidden');
    attendeeElement.querySelector('[co-attendee-edit-details]').classList.add('hidden');
    attendeeElement.querySelector('[co-attendee-edit-details]').classList.add('hidden');
  } else {
    attendeeElement.querySelector('[co-attendee-is-biller]').classList.add('hidden');
    attendeeElement.querySelector('[co-attendee-edit-details]').classList.remove('hidden');
    attendeeElement.querySelector('[co-attendee-edit-details]').classList.remove('hidden');
  }

  renderAttendeeForm(attendeeElement, attendeeData, itemOfType.item_key, attendeeIndex);
  addAttendeeEventListeners(attendeeElement);

  attendeesContainer.appendChild(attendeeElement);
}

function renderAttendeeForm(attendeeElement, attendeeData, item_key, attendeeIndex)
{
  const allKnownAttendees = oaUtils.getAllKnownAttendees();
  const formElement = attendeeElement.querySelector('[co-attendee-edit-form]');
  const attendeeDropdown = formElement.querySelector('[name="current_attendee"]');

  // Add the attendee options
  for(let uuid in allKnownAttendees) {
    if (uuid == 'biller') {
      continue;
    }
    const attendeeOption = document.createElement('option');
    attendeeOption.setAttribute('value', uuid);
    attendeeOption.innerText = allKnownAttendees[uuid].first_name + ' ' + allKnownAttendees[uuid].last_name;
    attendeeDropdown.appendChild(attendeeOption);
  }

  // Prepopulate the form
  formElement.item_key = item_key;
  formElement.attendeeIndex = attendeeIndex;
  formElement.noValidate = true;
  checkoutUtils.initialiseFormFields(formElement, attendeeData);
  attendeeDropdown.value = attendeeData.uuid;
}

function addAttendeeEventListeners(attendeeElement)
{
  // add the event listener to show the edit form
  attendeeElement
    .querySelector('[co-attendee-edit-toggle]')
    .addEventListener(
      'click',
      (event) => {
        attendeeElement.querySelector('[co-attendee-details]').classList.add('hidden');
        attendeeElement.querySelector('[co-attendee-edit-form]').classList.remove('hidden');
      },
      { passive: true }
    )
  ;

  // Add the event to show & hide the actual details form
  attendeeElement
    .querySelector('[name="current_attendee"]')
    .addEventListener(
      'change',
      (event) => {
        if (event.target.value == 'new') {
          attendeeElement.querySelectorAll('input').forEach((inputElement) => inputElement.value = '');
          attendeeElement.querySelector('[co-attendee-edit-details]').classList.remove('hidden');
          return;
        } else {
          attendeeElement.querySelector('[co-attendee-edit-details]').classList.add('hidden');
        }

        // Save the association between this slot/index and the attendee's uuid
        const formElement = event.target.closest('form');
        utils.set(`attendee_${formElement.item_key}_${formElement.attendeeIndex}`, event.target.value);
        utils.dispatchGlobal('elp:cart:attendee-changed');
      },
      { passive: true }
    )
  ;

  // Add the attendee save functionality
  attendeeElement
    .querySelector('form')
    .addEventListener(
      'submit',
      onAttendeeFormSubmit
    )
  ;
}

function onAttendeeFormSubmit(event) {
  event.preventDefault();

  const formElement = event.target;
  checkoutUtils.removeFormFieldInvalidations(formElement);
  const isValid = formElement.reportValidity();

  if (!isValid) {
    return;
  }

  oaUtils.setAttendeeData(
    formElement.item_key,
    formElement.attendeeIndex,
    checkoutUtils.formDataToObject(new FormData(formElement))
  );

  utils.dispatchGlobal('elp:cart:attendee-changed');
}

function addEventListeners() {
  // Add handlers to go to the next page
  state.template
    .querySelectorAll('[checkout-next]')
    .forEach(
      (nextBtn) => {
        nextBtn.addEventListener(
          'click',
          onFormSubmit
        );
      }
    )
  ;
}

function onFormSubmit(event) {
  utils.dispatchGlobal(
    'elp:cart:goto-page',
    {
      page: checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
    }
  );
}
