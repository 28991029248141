'use strict';

import * as utils from './utils.js';
import * as checkoutUtils from './checkout-utils.js';

let state = {
  currentPage: 0
};

export function init(globalState) {
  state.globalState = globalState;
  state.cartButton = document.querySelector('[elp-cart-button]');

  // Update the total on the cart button whenever the cart is loaded
  // Render the cart when we get a response
  /* document
    .querySelector('body')
    .addEventListener(
      'elp:cart:fetched',
      onCartFetched,
      { passive: true }
    )
  ; */

  globalState
    .checkoutContainer
    .querySelectorAll('[checkout-close]')
    .forEach(
      (closeBtn) => {
        closeBtn.addEventListener(
          'click',
          (event) => {
            utils.dispatchGlobal('elp:cart:close');
          },
          { passive: true }
        );
      }
    )
  ;

  globalState
    .checkoutContainer
    .querySelectorAll('[login-close]')
    .forEach(
  	(closeBtn) => {
  	  closeBtn.addEventListener(
  		'click',
  		(event) => {
  		  utils.dispatchGlobal('elp:login:close');
  		},
  		{ passive: true }
  	  );
  	}
    )
  ;


  // Listener for the "<- back" functionality
  globalState
    .checkoutContainer
    .querySelectorAll('[checkout-back]')
    .forEach(
      (backBtn) => {
        backBtn.addEventListener(
          'click',
          (event) => {
            const newPageId = checkoutUtils.fetchPreviousPageId(
              parseInt(
                event.target.closest('[co-step]')
                  .getAttribute('co-step')
              ),
              window.elpCartState.data
            );

            utils.dispatchGlobal('elp:cart:goto-page', { page: newPageId });
          },
          { passive: true }
        );
      }
    )
  ;

  // Clicks to the background should close the
  state.globalState.checkoutContainer.addEventListener(
    'click',
    (event) => {
      if (event.target === state.globalState.checkoutContainer) {
        utils.dispatchGlobal('elp:cart:close');
      }
    },
    { passive: true }
  );

  // "Open" happens early and will display the cart before there is content
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:open',
      (event) => {
        document.querySelector('body').classList.add('checkout-active')
      },
      { passive: true }
    )
  ;

  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:close',
      (event) => {
        document.querySelector('body').classList.remove('checkout-active')
      },
      { passive: true }
    )
  ;

  document
    .querySelector('body')
    .addEventListener(
  	'elp:login:close',
  	(event) => {
  	  document.querySelector('body').classList.remove('login-active')
  	},
  	{ passive: true }
    )
  ;

  document
    .querySelector('body')
    .addEventListener(
      'elp:login:close',
      (event) => {
        document.querySelector('body').classList.remove('login-active')
      },
      { passive: true }
    )
  ;

  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:render',
      doAddToCartRender,
      { passive: true }
    )
  ;

  // Add the fetch trigger to `Cart` buttons
  document
    .querySelectorAll('[elp-open="cart"]')
    .forEach(
      (cartButton) => {
        cartButton.addEventListener(
          'click',
          (event) => {
            document.querySelector('body').classList.add('checkout-active');
            utils.dispatchGlobal('elp:cart:goto-page', { page: 0 });
          },
          { passive: true }
        );
      }
    )
  ;

  // Add the fetch trigger to `Login` buttons
  document
    .querySelectorAll('[elp-open="login"]')
    .forEach(
      (loginButton) => {
        loginButton.addEventListener(
          'click',
          (event) => {
            document.querySelector('body').classList.add('login-active');
          },
          { passive: true }
        );
      }
    )
  ;

  document
	.querySelectorAll('.login-close')
	.forEach(
	  (loginButton) => {
		loginButton.addEventListener(
		  'click',
		  (event) => {
			document.querySelector('body').classList.remove('login-active');
		  },
		  { passive: true }
		);
	  }
	)
  ;

}

function onCartFetched(event) {
  let data = event.detail.data;

  if (
    utils.isEmptyArray(data)
    || data.item_count === 0
  ) {
    state.cartButton.innerText = 'Cart';
  } else {
    state.cartButton.innerText = `Cart (${data.item_count})`;
  }
}

function doAddToCartRender(event) {

  // Update the content if that has been sent
  if (
    'detail' in event
    && 'template' in event.detail
    && event.detail.template
  ) {
    const addToCartContainer = state
      .globalState
      .checkoutContainer
      .querySelector('[co-add-to-cart-container]')
    ;

    utils.emptyDomElement(addToCartContainer);
    addToCartContainer.append(event.detail.template);
    checkoutUtils.displayActivePage('add-to-cart');
  }

  utils.dispatchGlobal('elp:cart:rendered');
}
