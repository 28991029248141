'use strict';
/**
 * Step 1: Login / create account
 */

import * as utils from './utils.js';
import * as cartApi from "./checkout-cart-api.js";
import * as checkoutUtils from "./checkout-utils.js";
import * as nav from "./checkout-navigation.js";
import * as openAccessUtils from './open-access-utils.js';


let state = {
  pageId: 1,
  messages: {
    user_exists: 'We have a user with your email address, please enter your existing password above.',
    user_does_not_exist: 'It looks like this is your first time booking. Please set a password above.',
    invalid_credentials: 'Either the email address or password are invalid. Please try again, or reset your password by <a href="/password-reset/">clicking here</a>.'
  },
  isNewCustomer: null
}

export function init(globalState) {
  state.globalState = globalState;
  state.template = globalState.checkoutContainer.querySelector('[co-step="1"]');

  // listen for requests to change to this page
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:goto-page',
      (event) => {
        if (event.detail.page === state.pageId) {
          render();
          checkoutUtils.displayActivePage(state.pageId)
          //render();
          //utils.dispatchGlobal('elp:cart:render', { template: state.template });
        }
      },
      { passive: true }
    )
  ;

  addEventListeners();
}

function render() {
  //console.log(`Rendering #${state.pageId}`);

  let customerData;

  if ('customer' in state.globalState.data) {
    customerData = state.globalState.data.customer.billing_address;
  } else {
    customerData = {};
  }

  checkoutUtils.initialiseFormFields(state.template, customerData);
  utils.dispatchGlobal('elp:cart:rendered');
}

function addEventListeners() {
  // Add handlers to go to the next page
  state.template
    .querySelectorAll('[checkout-next]')
    .forEach(
      (nextBtn) => {
        nextBtn.addEventListener(
          'click',
          onFormSubmit
        );
      }
    )
  ;

  // Add handlers to go to the next page
  state.template
    .querySelector( 'input[type="email"]' )
    .addEventListener( 'blur', onEmailFieldLosingFocus )
  ;
}

function onEmailFieldLosingFocus(event) {
  let emailAddress = event.target.value;
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) {
    event.target.dispatchEvent(new CustomEvent('invalid'))
  } else {
    cartApi.checkUserExists(
      emailAddress,
      (data) => {

        if ('exists' in data) {
          if (data.exists) {
            state.isNewCustomer = false;
            setUserMessage(state.messages.user_exists);

          } else {
            state.isNewCustomer = true;
            setUserMessage(state.messages.user_does_not_exist);
          }
        } else {
          setUserMessage('');
        }
      }
    )
  }
}

function setUserMessage(message) {
  state
    .template
    .querySelector('[co-account-exists-message]')
    .innerHTML = message
  ;
}

function onFormSubmit(event) {
  event.preventDefault();

  utils.dispatchGlobal('elp:account:login');

  const formElement = state.template.querySelector('.form');
  checkoutUtils.removeFormFieldInvalidations(formElement);

  //const isValid = formElement.reportValidity();

  //if (isValid) {
    const email = state.template.querySelector('[name="billing_email"]').value;
    const password = state.template.querySelector('[name="account_password"]').value;

    cartApi.loginOrCreateCustomer(
      email,
      password,
      (responseData) => {
        if (
          'id' in responseData
          && responseData.id
        ) {
          state.globalState.data = checkoutUtils.mergeCustomerData(
            state.globalState.data,
            responseData
          );

          utils.set('user_id', responseData.id);
          utils.set('email', email);
          utils.set('password', password);
          utils.set('x-elp-authorization', btoa(email + ":" + password));

		  utils.dispatchGlobal(
	        'elp:cart:goto-page',
	        {
	          page: checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
	        }
	      );

          //checkoutUtils.reloadPageToSpecificCheckoutStep(
            //checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
		  //);

          /*
          cartApi.fetchCoCart(cartApi.FORCE_NEW_CO_CART);
          cartApi.fetchCart();

          utils.dispatchGlobal(
            'elp:cart:goto-page',
            {
              page: checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
            }
          );
          */
        } else {
          setUserMessage(state.messages.invalid_credentials);
          utils.dispatchGlobal('elp:cart:rendered');
        }
      }
    );
  //}
}
