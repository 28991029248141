'use strict';

import * as utils from "./utils.js";

export function init() {
  addEventListeners();
}

function addEventListeners() {

  document.querySelectorAll('.wc_payment_methods .checkbox')
  .forEach(
	(paymentCheckbox) => {
	  paymentCheckbox.addEventListener(
		'click',
		(e) => {
			let placeOrder = document.getElementById('place_order');
			placeOrder.style = "display:block !important"
		}
	  );
	}
  );

  document.querySelectorAll('.addattendee')
  .forEach(
	(attendeeButton) => {
	  attendeeButton.addEventListener(
		'click',
		(e) => {
			let next_form = e.target.getAttribute('data-next');
			let form = document.getElementById(next_form);
			form.classList.remove('hidden');
		}
	  );
	}
  );

  document.querySelectorAll('.remove-attendee')
  .forEach(
	(removeButton) => {
	  removeButton.addEventListener(
		'click',
		(e) => {
			let next_form = e.target.getAttribute('data-hide');
			let form = document.getElementById(next_form);
			form.classList.add('hidden');
		}
	  );
	}
  );

  document
    .querySelector('body')
    .addEventListener(
      'elp:account:logout',
      renderTopMenu
    )
  ;

  document
    .querySelector('body')
    .addEventListener(
      'elp:account:login',
      renderTopMenu
    )
  ;

  // Perform a logout
  document
    .querySelectorAll('[elp-logout]')
    .forEach(
      (logoutButton) => {
        logoutButton.addEventListener(
          'click',
          (event) => {
            utils.dispatchGlobal('elp:account:logout');
          },
          { passive: true }
        );
      }
    )
  ;
}

function renderTopMenu(event)
{
  //.log(' §§ rendering top menu!', event);
  // TODO

  switch ( event.type ) {
    case 'elp:account:login':
      break;

    case 'elp:account:logout':
      break;
  }
}
