'use strict';
/**
 * Step 0: Shopping cart
 */

import * as utils from "./utils.js";
import * as cartApi from "./checkout-cart-api.js";
import * as checkoutUtils from "./checkout-utils.js";
import * as openAccessUtils from './open-access-utils.js';

let state = {
  pageId: 0
};

export function init(globalState) {
  state.globalState = globalState;
  state.template = globalState.checkoutContainer.querySelector('[co-step="0"]');

  // listen for requests to change to this page
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:goto-page',
      (event) => {
        if (event.detail.page === state.pageId) {
          // now trigger a cart render, and pass in the rendered template
          checkoutUtils.displayActivePage(state.pageId)
        }
      },
      { passive: true }
    )
  ;

  // Render the cart when we get a response
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:fetched',
      render,
      { passive: true }
    )
  ;
}

function addEventListeners() {
  // Add handlers to go to the next page
  state.template
    .querySelectorAll('[checkout-next]')
    .forEach(
      (nextBtn) => {
        nextBtn.addEventListener(
          'click',
          (event) => {
            utils.dispatchGlobal(
              'elp:cart:goto-page',
              {
                page: checkoutUtils.fetchNextPageId(0, state.globalState.data)
              }
            );
          },
          { passive: true }
        );
      }
    )
  ;

  // Show / hide the coupon code input
  state.template
    .querySelector('[checkout-show-coupon-input]')
    .addEventListener(
      'change',
      (event) => {

        const checkboxContainer = event.target.closest('.checkbox-container');
        const couponContainer = event
          .target
          .closest('form')
          .querySelector('[checkout-coupon-container]')
        ;

        if (event.target.checked) {
          couponContainer.classList.remove('hidden');
          checkboxContainer.classList.add('hidden');
        } else {
          couponContainer.classList.add('hidden');
          checkboxContainer.classList.remove('hidden');
        }
      },
      { passive: true }
    )
  ;

  // Submit voucher code
  state.template
    .querySelector('[checkout-coupon-form]')
    .addEventListener( 'submit', doCouponSubmit )
  ;
}

function doCouponSubmit(event)
{
  event.preventDefault();

  const formElement = event.target;
  checkoutUtils.removeFormFieldInvalidations(formElement);
  const isValid = formElement.reportValidity();

  if (!isValid) {
    return;
  }

  utils.dispatchGlobal('elp:cart:changed');

  const couponData = {
    coupon: formElement.querySelector('[name="coupon_code"]').value
  }

  cartApi.applyCoupon(couponData);
}

function doCouponRemove(event)
{
  utils.dispatchGlobal('elp:cart:changed');

  const couponData = {
    coupon: event.target.closest('.co-item').couponCode
  };

  cartApi.removeCoupon(couponData);
}

// Reset the coupon form when the page is re-rendered
function renderCouponForm()
{
  state.template.querySelector('[checkout-show-coupon-input]').checked = false;
  state.template.querySelector('[name="coupon_code"]').value = '';
  state.template.querySelector('[checkout-coupon-container]').classList.add('hidden');
}

function render(event)
{
  //console.log(`Rendering #${state.pageId}`);

  let data = event.detail.data;

  if (
    utils.isEmptyArray(data)
    || data.item_count === 0
  ) {
    renderEmptyCart(data);
  } else {
    renderCouponForm();
    sortCartItems(data.items);
    renderCartItems(data);
    renderActiveCoupons(data);
    renderCartTotals(data);
    addEventListeners();
  }
  utils.dispatchGlobal('elp:cart:rendered');
}

/* Sort cart items so it goes (type scores in brackets);
 *  - products (40) - these are then ordered by title
 *  - courses (30) - these are then ordered by start date
 *  - Open Access (20) - these are then ordered by start date
 *  - Induction for open access (10) - these are then added at the end
 */
function sortCartItems(cartItems) {

  function getItemTypeSortingScore(cartItem) {
    switch (cartItem.meta.product_type) {
      case 'simple':
        if (cartItem.name == 'Studio Induction') {
          return 10;
        }

        return 40;

      case 'booking':
        if (cartItem.name == 'Open Access') {
          return 20;
        }

        return 30;
    }

    return 0;
  }

  cartItems.sort(
    (itemA, itemB) => {
      if (getItemTypeSortingScore(itemA) > getItemTypeSortingScore(itemB)) {
        return -1;
      } else if (getItemTypeSortingScore(itemA) < getItemTypeSortingScore(itemB)) {
        return 1;
      } else {
        // They are both the same type. What do we use to compare?
        let comparisonValueA, comparisonValueB;
        switch (getItemTypeSortingScore(itemA)) {
          case 30:
          case 20:
            // It's a course or OA. Use the start date
            comparisonValueA = itemA.cart_item_data.wc_bookings_field_start_date_time;
            comparisonValueB = itemB.cart_item_data.wc_bookings_field_start_date_time;
            break;

          default:
            // It's a product (or induction), use the name
            comparisonValueA = itemA.name;
            comparisonValueB = itemB.name;
        }

        if (comparisonValueA < comparisonValueB) {
          return -1;
        } else if (comparisonValueA > comparisonValueB) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  );
}

function renderEmptyCart(data) {
  //state.template.classList.add('co-is-empty');
  let emptyCartTemplate = utils.cloneTemplate('[elp-template="cart-empty"]');
  let checkoutBody = state.template.querySelector('[co-body]');
  renderCartNotices(emptyCartTemplate, data);
  utils.emptyDomElement(checkoutBody);
  checkoutBody.appendChild(emptyCartTemplate);
}

function renderCartNotices(noticesContainer, data) {
  if (
    utils.isEmptyArray(data)
    || !data.notices.length
  ) {
    return;
  }

  for(let i in data.notices.notice) {
    let noticeElement = document.createElement('p');
    noticeElement.innerHTML = data.notices.notice[i];
    noticeContainer.appendChild(noticeElement);
  }
}

function renderCartItems(data) {
  state.template.classList.remove('co-is-empty');

  let checkoutBody = state.template.querySelector('[co-body]');
  utils.emptyDomElement(checkoutBody);

  if (data.notices.length) {
    let noticesContainer = state.template.querySelector('[co-notices]');
    renderCartNotices(noticesContainer, data);
  }

  for(let i in data.items) {
    let cartItemTemplate = utils.cloneTemplate('[elp-template="cart-item"]');

    renderCartItemImage(cartItemTemplate, data.items[i]);
    renderCartItemDescription(cartItemTemplate, data.items[i]);
    renderCartItemPrice(cartItemTemplate, data.items[i]);

    // Add listener for when the qty is updated
    cartItemTemplate.addEventListener(
      'elp:cart:qty-changed',
      onCartItemQuantityChange,
      { passive: true }
    );

    checkoutBody.appendChild(cartItemTemplate);
  }
}

function renderActiveCoupons(data) {
  if (
    !('coupons' in data)
    || !Array.isArray(data.coupons)
    || !data.coupons.length
  ) {
    //console.log('No coupons');
    return;
  }

  const checkoutBody = state.template.querySelector('[co-body]');

  for(let i in data.coupons) {
    const cartItemTemplate = utils.cloneTemplate('[elp-template="cart-item"]');

    // Description
    const descriptionElement = cartItemTemplate.querySelector('[co-item-description]');
    cartItemTemplate.couponCode = data.coupons[i].coupon;

    const h4Tag = document.createElement('h4');
    h4Tag.innerText = `Voucher: ${data.coupons[i].coupon}`;
    descriptionElement.appendChild(h4Tag);

    const pTag = document.createElement('p');
    pTag.innerText = `Remove`;
    pTag.classList.add('link');
    descriptionElement.appendChild(pTag);

    pTag.addEventListener('click', doCouponRemove, { passive: true } );

    // Price
    cartItemTemplate
      .querySelector('[co-item-price]')
      .innerHTML = data.coupons[i].saving_html
    ;

    // Add listener for when the qty is updated
    cartItemTemplate.addEventListener(
      'elp:cart:qty-changed',
      onCartItemQuantityChange,
      { passive: true }
    );

    checkoutBody.appendChild(cartItemTemplate);
  }
}

function onCartItemQuantityChange(event) {
  // Do nothing if it hasn't actually changed
  if (event.detail.newValue === event.detail.previousValue) {
    return;
  }

  if (event.detail.newValue <= 0) {
    cartApi.removeCartItem(event.detail.data.item_key);
    return;
  }

  switch(event.detail.data.name) {
    case 'Open Access':
      openAccessUtils.updateOpenAccessSessionQuantity(
        event.detail.data,
        event.detail.newValue
      );
      break;

    default:
      cartApi.updateCartItemQuantity(
        event.detail.data.item_key,
        event.detail.newValue
      );
      break;
  }
}

function renderCartItemImage(cartItemTemplate, cartItemData) {
  if (
    !cartItemData.hasOwnProperty('featured_image')
    || !cartItemData.featured_image
  ) {
    return;
  }

  let featuredImageContainer = cartItemTemplate.querySelector('[co-item-featured-image]');
  let featuredImage = document.createElement('img');
  featuredImage.setAttribute('src', cartItemData.featured_image);
  
  featuredImageContainer.appendChild(featuredImage);
}

function renderCartItemDescription(cartItemTemplate, cartItemData) {
  const descriptionContainer = cartItemTemplate.querySelector('[co-item-description]');

  switch(cartItemData.meta.product_type) {
    case 'booking':
      if (cartItemData.name == 'Open Access') {
        renderOpenAccessDescription(descriptionContainer, cartItemData);
        // Do a query to see how many spaces are available in real-time
        openAccessUtils.fetchSingleTimeslotAndResourceAvailability(
          cartItemData.id,
          cartItemData.cart_item_data.wc_bookings_field_resource,
          new Date(cartItemData.cart_item_data.wc_bookings_field_start_date_time.substr(0,16)),
          (data) => {
            utils.addQuantitySelector(
              descriptionContainer,
              cartItemData.cart_item_data.wc_bookings_field_persons,
              0,
              data.available,
              cartItemData
            );
          }
        )
      } else {
        // TODO; add course descriptions & qty selector
        let title = document.createElement('h3');
        title.innerText = cartItemData.name;
        descriptionContainer.appendChild(title);
      }
      break;

    default:
      renderStandardDescription(descriptionContainer, cartItemData);
      utils.addQuantitySelector(
        descriptionContainer,
        cartItemData.quantity.value,
        0,
        cartItemData.quantity.max_purchase,
        cartItemData
      );
      break;
  }
}

function renderStandardDescription(descriptionContainer, cartItemData) {
  let title = document.createElement('h3');
  title.innerText = cartItemData.name;
  descriptionContainer.appendChild(title);

  if (cartItemData.short_description) {
    let description = document.createElement('p');
    description.innerHTML = cartItemData.short_description;
    descriptionContainer.appendChild(description);
  }
}

function renderOpenAccessDescription(descriptionContainer, cartItemData) {
  let title = document.createElement('h3');
  let subtitle = document.createElement('h4');;
  let description = [];

  for(let key in cartItemData.cart_item_data) {
    switch (key) {
      case 'wc_bookings_field_resource':
        title.innerText = openAccessUtils
          .getResourceNameById(
            cartItemData.cart_item_data[key]
          )
        ;
        break;

      case 'wc_bookings_field_start_date_time':
        let bookingDate = new Date(cartItemData.cart_item_data[key]);
        subtitle.innerText = utils.dateToHuman(bookingDate);

        let line1 = document.createElement('p');
        let line2 = document.createElement('p');

        if (bookingDate.getHours() < 12) {
          line1.innerText = 'Morning Session:';
          line2.innerText = 'From 10:00 to 14:00';
        } else {
          line1.innerText = 'Afternoon Session:';
          line2.innerText = 'From 15:00 to 19:00';
        }

        description.push(line1);
        description.push(line2);
        break;
    }
  }

  // TODO; add the line "(Associate member)" to the description[] array if the user is getting the discounted price

  descriptionContainer.appendChild(title);
  descriptionContainer.appendChild(subtitle);
  for (let i in description) {
    descriptionContainer.appendChild(description[i]);
  }
}

function renderCartItemPrice(cartItemTemplate, cartItemData) {
  let cartItemPriceContainer = cartItemTemplate.querySelector('[co-item-price]');
  cartItemPriceContainer.innerText = '£' + cartItemData.totals.total.toFixed(2);
}

function renderCartTotals(data) {
  let totalsContainer = state.template.querySelector('[co-cart-totals]');
  utils.emptyDomElement(totalsContainer);

  let tbody = document.createElement('tbody');
  let tfoot = document.createElement('tfoot');

  const format = (num, fraction = 2) => new Intl.NumberFormat([], {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(num);

  renderCartTotalsRow(tbody, 'Subtotal', format(data.totals.subtotal/100));
  renderCartTotalsRow(tbody, 'Discount', data.totals.discount_total);
  //if (data.shipping.has_calculated_shipping) {
    renderCartTotalsRow(tbody, 'Estimated shipping', format(data.totals.shipping_total/100));
  //}

  renderCartTotalsRow(tfoot, 'Total', format(data.totals.total/100));

  totalsContainer.appendChild(tbody);
  totalsContainer.appendChild(tfoot);
}

function renderCartTotalsRow(template, label, value) {
  let row = document.createElement('tr');
  let th = document.createElement('th');
  let td = document.createElement('td');
  th.innerText = label;
  td.innerText = '£' + value;
  row.appendChild(th);
  row.appendChild(td);
  template.appendChild(row);
}

export function fetchCartHtml()
{
  return state.template.querySelector('[co-body]').cloneNode(true);
}

export function fetchTotalsHtml()
{
  return state.template.querySelector('[co-cart-totals]').cloneNode(true);
}
