'use strict';
/**
 * Step 2: Billing
 */

import * as cartApi from "./checkout-cart-api.js";
import * as checkoutUtils from "./checkout-utils.js";
import * as nav from "./checkout-navigation.js";
import * as utils from './utils.js';

let state = {
  pageId: 2
}

export function init(globalState) {
  state.globalState = globalState;
  state.template = globalState.checkoutContainer.querySelector('[co-step="2"]');
  addEventListeners();

  // listen for requests to change to this page
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:goto-page',
      (event) => {
        if (event.detail.page === state.pageId) {
          render();
          checkoutUtils.displayActivePage(state.pageId);
        }
      },
      { passive: true }
    )
  ;

  // Re-render whenever the cart is re-fetched as we may have updated data
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:fetched',
      render,
      { passive: true }
    )
  ;
}

function render() {
  //console.log(`Rendering #${state.pageId}`);

  renderIntro();
  renderFormData();
  utils.dispatchGlobal('elp:cart:rendered');
}

function addEventListeners() {
  // Add handlers to go to the next page
  state.template
    .querySelectorAll('[checkout-next]')
    .forEach(
      (nextBtn) => {
        nextBtn.addEventListener(
          'click',
          onFormSubmit
        );
      }
    )
  ;
}

function renderIntro() {
  // "Step [x]"
  state
    .template
    .querySelector('[co-current-checkout-step]')
    .innerText = checkoutUtils.fetchCurrentPageNumber(state.pageId, state.globalState.data)
  ;

  // "of [y]"
  state
    .template
    .querySelector('[co-total-checkout-steps]')
    .innerText = checkoutUtils.totalNumberOfCheckoutSteps(state.globalState.data)
  ;

  // Remove mention of attendees if there are no course or OA sessions in the basket
  if (
    !checkoutUtils.basketContainsOpenAccess(state.globalState.data)
    && !checkoutUtils.basketContainsCourse(state.globalState.data)
  ) {
    state.template.querySelector('[co-content-if="has-open-access"]').innerText = '';
  }
}

function renderFormData() {
  let formElement = state.template.querySelector('form');
  let customerData;

  if ('customer' in state.globalState.data) {
    customerData = state.globalState.data.customer.billing_address;
  } else {
    customerData = {};
  }

  formElement.noValidate = true;

  checkoutUtils.initialiseFormFields(state.template, customerData);
}

function onFormSubmit(event) {
  event.preventDefault();

  const formElement = state.template.querySelector('form');
  checkoutUtils.removeFormFieldInvalidations(formElement);

  const isValid = formElement.reportValidity();

  if (isValid) {
    utils.dispatchGlobal('elp:cart:changed');

    checkoutUtils.updateTrueForm(
      state.globalState.trueForm,
      state.template.querySelector('[co-fake-form]'),
      {
        billing_email: utils.get('email')
      }
    );

    utils.dispatchGlobal(
      'elp:cart:goto-page',
      {
        page: checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
      }
    );
  }
}
