'use strict';
/**
 * Step 4: Shipping
 */

import * as cartApi from "./checkout-cart-api.js";
import * as checkoutUtils from "./checkout-utils.js";
import * as nav from "./checkout-navigation.js";
import * as utils from './utils.js';

let state = {
  pageId: 4,
  shipToDifferentAddress: false
}

export function init(globalState) {
  state.globalState = globalState;
  state.template = globalState.checkoutContainer.querySelector('[co-step="4"]');
  mapTrueForm();

  if (utils.get('ship_to_different_address') == null) {
    utils.set('ship_to_different_address', false);
  }

  addEventListeners();

  // listen for requests to change to this page
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:goto-page',
      (event) => {
        if (event.detail.page === state.pageId) {
          render();
          checkoutUtils.displayActivePage(state.pageId);
        }
      },
      { passive: true }
    )
  ;

  // Re-render whenever the cart is re-fetched as we may have updated data
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:fetched',
      render,
      { passive: true }
    )
  ;
}

function mapTrueForm()
{
  checkoutUtils.mapFakeFormToTrueForm(
    state.template.querySelector('[co-fake-form]'),
    state.globalState.trueForm
  );
}

function render() {
  //console.log(`Rendering #${state.pageId}`);

  renderIntro();
  renderBillingAddress();
  renderFormData();
  addFormEventListeners();
  showShippingForm(utils.get('ship_to_different_address'));
  utils.dispatchGlobal('elp:cart:rendered');
}

function addEventListeners()
{
  // Add handlers to go to the next page
  state.template
    .querySelectorAll('[checkout-next]')
    .forEach(
      (nextBtn) => {
        nextBtn.addEventListener(
          'click',
          onFormSubmit
        );
      }
    )
  ;
}

function addFormEventListeners()
{
  state.template
    .querySelector('[name="ship_to_different_address"]')
    .addEventListener(
      'click',
      (event) => {
        utils.set('ship_to_different_address', event.target.checked);
        showShippingForm(utils.get('ship_to_different_address'));
        renderIntro();
      }
    )
  ;
}

function showShippingForm(showFields)
{
  //console.log(`showShippingForm(${showFields})`);
  if (showFields) {
    state.template.querySelector('.shipping-container').classList.remove('hidden');
    state.template.querySelector('.billing-address').classList.add('hidden');
  } else {
    state.template.querySelector('.shipping-container').classList.add('hidden');
    state.template.querySelector('.billing-address').classList.remove('hidden');
  }

  state.template
    .querySelectorAll('.shipping-container input')
    .forEach(
      (inputElement) => {
        inputElement.setAttribute.disabled = !showFields;
      }
    )
  ;
}

function renderIntro() {
  // "Step [x]"
  state
    .template
    .querySelector('[co-current-checkout-step]')
    .innerText = checkoutUtils.fetchCurrentPageNumber(state.pageId, state.globalState.data)
  ;

  // "of [y]"
  state
    .template
    .querySelector('[co-total-checkout-steps]')
    .innerText = checkoutUtils.totalNumberOfCheckoutSteps(state.globalState.data)
  ;

  let introMessage;
  if ( utils.get('ship_to_different_address') ) {
    introMessage = 'Please insert your shipping details in the boxes below.';
  } else {
    introMessage = 'Ship to your billing address. Tick the box below to change this.';
  }

  state
    .template
    .querySelector('.form-intro')
    .innerText = introMessage
  ;
}

function renderBillingAddress()
{
  state
    .template
    .querySelector('[checkout-billing-addres]')
    .innerHTML = "Ship to billing address:<br/>" + checkoutUtils.buildBillingAddressHtml(state.globalState.trueForm)
  ;
}

function renderFormData() {
  let formElement = state.template.querySelector('form');
  let customerData;

  if ('customer' in state.globalState.data) {
    customerData = state.globalState.data.customer.shipping_address;
  } else {
    customerData = {};
  }

  formElement.noValidate = true;

  // set the checkbox
  state.template
    .querySelector('[name="ship_to_different_address"]')
    .checked = utils.get('ship_to_different_address')
  ;

  checkoutUtils.initialiseFormFields(state.template, customerData);
  showShippingForm(utils.get('ship_to_different_address'));
}

function onFormSubmit(event) {
  event.preventDefault();

  const formElement = state.template.querySelector('form');

  checkoutUtils.removeFormFieldInvalidations(formElement);

  const isValid = formElement.reportValidity();

  if (isValid) {
    utils.dispatchGlobal('elp:cart:changed');

    utils.dispatchGlobal(
      'elp:cart:goto-page',
      {
        page: checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
      }
    );

    /*
    const userData = {
      shipping_address: {
        first_name: state.template.querySelector('[name="shipping_first_name"]').value,
        last_name: state.template.querySelector('[name="shipping_last_name"]').value,
        address_1: state.template.querySelector('[name="shipping_address_1"]').value,
        city: state.template.querySelector('[name="shipping_city"]').value,
        postcode: state.template.querySelector('[name="shipping_postcode"]').value,
      }
    };

    cartApi.updateCustomer(
      userData,
      (data) => {
        if (data === true) {

          utils.dispatchGlobal(
            'elp:cart:goto-page',
            {
              page: checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
            }
          );
        } else {
          // TODO: do something if we get a bad response here
        }
      }
    );
    */
  }
}
