'use strict';

import * as utils from './utils.js';

export function userIsLoggedIn() {
  // We were logged in when this page loaded
  if (
    typeof window.elpUserData === 'object'
    && 'id' in window.elpUserData
    && parseInt(window.elpUserData.id) > 0
  ) {
    return true;
  }

  // We have a stored email and password, so we must have logged in since the page loaded
  if (
    utils.get('email')
    && utils.get('password')
  ) {
    return true;
  }

  return false;
}

export function basketRequiresAttendeeData(cartData) {
  if (typeof cartData === 'undefined') {
    cartData = window.elpCartState.data;
  }
  return (basketContainsOpenAccess(cartData) || basketContainsCourse(cartData));
}

export function basketContainsOpenAccess(cartData) {
  if (typeof cartData === 'undefined') {
    cartData = window.elpCartState.data;
  }

  let hasOpenAccess = false;

  if ('items' in cartData) {
    cartData.items.forEach(
      (cartItem) => {
        if (cartItem.name === 'Open Access') {
          hasOpenAccess = true;
        }
      }
    );
  }

  return hasOpenAccess;
}

export function basketContainsCourse(cartData) {
  let hasCourse = false;

  if ('items' in cartData) {
    cartData.items.forEach(
      (cartItem) => {
        if (
          cartItem.meta === 'booking'
          && cartItem.name !== 'Open Access'
        ) {
          hasCourse = true;
        }
      }
    );
  }

  return hasCourse;
}

/**
 * This will prepopulate form fields from an object of
 * data where the input name="" matches the key of the
 * object.
 *
 * Additionally it will add the invalid handler to add
 * the UI feedback when the form is invalid
 * */
export function initialiseFormFields(template, formData) {
  template
    .querySelectorAll('input,textarea')
    .forEach(
      (formElement) => {
        // What do we do when fields are invalid
        formElement
          .addEventListener(
            'invalid',
            (event) => {
              formElement.classList.add('invalid');
              formElement.setAttribute('aria-invalid', true);
            }
          )
        ;

        // Match the field name, with the data in the cart
        let name = formElement.getAttribute('name');
        if (name in formData) {
          switch (formElement.getAttribute('type')) {
            case 'checkbox':
            case 'radio':
              formElement.checked = formData[name] ? true : false;
              break;

            default:
              formElement.value = formData[name];
              break;
          }
        }
      }
    )
  ;
}

export function updateTrueForm(trueForm, fakeForm, overrideData) {
  fakeForm.querySelectorAll('input,textarea').forEach((fakeFormElement) => {
    const fakeFormElementName = fakeFormElement.getAttribute('name');
    const trueFormElement = trueForm.querySelector(`[name="${fakeFormElementName}"]`);

    if (trueFormElement) {
      switch (trueFormElement.getAttribute('type')) {
        case 'checkbox':
        case 'radio':
          trueFormElement.checked = fakeFormElement.checked;
          //console.log(`Updating ${fakeFormElementName} checkbox to ${fakeFormElement.checked}`);
          break;

        default:
          trueFormElement.value = fakeFormElement.value;
          //console.log(`Updating ${fakeFormElementName} input to ${fakeFormElement.value}`);
          break;
      }
    }
  });

  if (overrideData) {
    for (let i in overrideData) {
      const trueFormElement = trueForm.querySelector(`[name="${i}"]`);
      if (trueFormElement) {
        trueFormElement.value = overrideData[i];
      }
    }
  }
}

export function formDataToObject(formData) {
  var object = {};
  formData.forEach((value, key) => object[key] = value);
  return object;
}

/**
 * This removed the "invalid" class and the "aria-invalid"
 * attribute from all form fields (used prior to
 * revalidation).
 */
export function removeFormFieldInvalidations(template) {
  template
    .querySelectorAll('input,textarea')
    .forEach(
      (formElement) => {
        formElement.classList.remove('invalid');
        formElement.removeAttribute('aria-invalid');
      }
    )
  ;
}

/**
 *  The number of checkout steps depends on:
 *   - if things need to be posted
 *   - if there is any open access / or courses in the basket
 */
export function totalNumberOfCheckoutSteps(cartData) {
  let totalSteps = 3;

  if (basketRequiresAttendeeData(cartData)) {
    totalSteps++;
  }

  if (cartData.needs_shipping) {
    totalSteps++;
  }

  return totalSteps;
}

export function fetchNextPageId(currentPageId, cartData) {

  switch (currentPageId) {
    // cart
    case 0:
      if ( userIsLoggedIn() ) {
        return 2;
      } else {
        return 1;
      }
      break;

    // login
    case 1:
      return 2;
      break;

    // biller info
    case 2:
      if ( basketRequiresAttendeeData( cartData ) ) {
        return 3;
      } else {
        if (cartData.needs_shipping) {
          return 4;
        } else {
          return 5;
        }
      }
      break;

    // attendee
    case 3:
      if (cartData.needs_shipping) {
        return 4;
      } else {
        return 5;
      }
      break;

    // shipping
    case 4:
      return 5;
      break;

    // Payment
    case 5:
      return 6;

    // Summary
    case 6:
      return 7;

    default:
      console.error(`Attempted to navigate from unknown page "${currentPageId}"`);
      break;
  }
}

export function fetchPreviousPageId(currentPageId, cartData) {

  switch (currentPageId) {

    case 1:   // login
      return 0;
      break;

    case 2:   // biller info
    case "2":
      return 0;
      break;

    // attendee
    case 3:
      return 2;
      break;

    // shipping
    case 4:
      if ( basketRequiresAttendeeData( cartData ) ) {
        return 3;
      } else {
        return 2;
      }
      break;

    // Payment
    case 5:
      if (cartData.needs_shipping) {
        return 4;
      } else {
        if ( basketRequiresAttendeeData( cartData ) ) {
          return 3;
        } else {
          return 2;
        }
      }

    default:
      console.error(`Attempted to navigate from unknown page "${currentPageId}"`);
      break;
  }
}

/**
 * The "step [x] of" value differs from the currentPageId depending
 * on what, if any, steps can be skipped
 */
export function fetchCurrentPageNumber(currentPageId, cartData) {
  switch (currentPageId) {
    // cart - we don't actually use a label on cart, or login/account
    case 0:
    case 1:
      break;

    // billing info
    case 2:
      return 1;
      break;

    // attendee
    case 3:
      return 2;
      break;

    // Shipping
    case 4:
      if ( basketRequiresAttendeeData( cartData ) ) {
        return 3;
      } else {
        return 2;
      }
      break;

    case 5:
      let pageNumber = 2;
      if ( basketRequiresAttendeeData( cartData ) ) {
        pageNumber++;
      }
      if (cartData.needs_shipping) {
        pageNumber++;
      }
      return pageNumber;
  }
}

export function displayActivePage(activePageId) {
  const checkoutElement = document.getElementById('checkout');
  for(let i = 0; i<8; i++) {
    checkoutElement.classList.remove(`step-${i}-active`);
  }

  checkoutElement.classList.remove(`step-add-to-cart-active`);

  checkoutElement.classList.add(`step-${activePageId}-active`);
}

export function mergeCustomerData(cartData, customerData) {
  cartData.id = customerData.id;

  const sectionNames = ['billing_address', 'shipping_address'];

  sectionNames.forEach(
    (sectionName) => {
      if (
        sectionName in customerData
        && sectionName in cartData
      ) {
        for (let key in customerData[sectionName]) {
          cartData[sectionName][key] = cartData[sectionName][key] ? cartData[sectionName][key] : customerData[sectionName][key];
        }
      }
    }
  );

  return cartData;
}

export function buildBillingAddressHtml(formElement)
{
  //console.log('buildBillingAddressHtml');
  //try {
    const fieldOrder = [
      'billing_address_1',
      'billing_address_2',
      'billing_city',
      'billing_state',
      'billing_postcode'
    ];

    return buildAddressHtml(
      'billing_first_name',
      'billing_last_name',
      formElement,
      fieldOrder
    );
    /*
  } catch (error) {
    return '';
  }*/
}

export function buildShippingAddressHtml(formElement)
{
  try {
    const fieldOrder = [
      'shipping_address_1',
      'shipping_address_2',
      'shipping_city',
      'shipping_state',
      'shipping_postcode'
    ];

    return buildAddressHtml(
      'shipping_first_name',
      'shipping_last_name',
      formElement,
      fieldOrder
    );
  } catch (error) {
    return '';
  }
}

function buildAddressHtml(firstnameSelector, lastnameSelector, formElement, fieldOrder)
{
  const firstnameElement = formElement.querySelector(`[name="${firstnameSelector}"]`);
  const firstname = firstnameElement ? firstnameElement.value : '';

  const lastnameElement = formElement.querySelector(`[name="${lastnameSelector}"]`);
  const lastname = lastnameElement ? lastnameElement.value : '';

  const addressArray = [`${firstname} ${lastname}`];

  fieldOrder.forEach(
    (fieldName) => {
      const fieldElement = formElement.querySelector(`[name="${fieldName}"]`);

      if (
        fieldElement
        && fieldElement.value
      ) {
        addressArray.push( fieldElement.value );
      }
    }
  );

  return addressArray.join('<br />');
}

export function mapFakeFormToTrueForm(fakeForm, trueForm)
{
  fakeForm
    .querySelectorAll('input,textarea')
    .forEach(
      (fakeElement) => {
        let trueElement;

        // Set the initial value / state
        switch(fakeElement.getAttribute('type')) {
          case 'radio':
          case 'checkbox':
            trueElement = trueForm.querySelector(`input[value="${fakeElement.value}"]`);
            if (trueElement) {
              trueElement.checked = fakeElement.checked;
            }
            break;

          case 'text':
          case 'date':
          case 'email':
          default:
            trueElement = trueForm.querySelector(`input[name="${fakeElement.name}"]`);
            if (trueElement) {
              trueElement.value = fakeElement.value;
            }
        }

        // Add a listener for when anything changes
        if (trueElement) {
          fakeElement.addEventListener(
            'change',
            (event) => {
              switch(event.target.getAttribute('type')) {
                case 'radio':
                case 'checkbox':
                  trueElement.checked = event.target.checked;
                  break;

                case 'text':
                case 'date':
                case 'email':
                  trueElement.value = event.target.value;
              }
            }
          );
        }
      }
    )
  ;
}

export function reloadPageToSpecificCheckoutStep(targetPage) {
  window.location.href = `${location.href}?elp_checkout_step=${targetPage}`;
}
