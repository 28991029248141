'use strict';
/**
 * Step 5: Payment
 */

import * as cartApi from "./checkout-cart-api.js";
import * as checkoutUtils from "./checkout-utils.js";
import * as nav from "./checkout-navigation.js";
import * as utils from './utils.js';

let state = {
  pageId: 5,
  payment_method: 'ppcp-credit-card-gateway'            // default to card payment
}

export function init(globalState) {
  state.globalState = globalState;
  state.template = globalState.checkoutContainer.querySelector('[co-step="5"]');
  addEventListeners();

  if (!utils.getSession('payment_method')) {
    utils.setSession('payment_method', state.payment_method);
  }

  // listen for requests to change to this page
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:goto-page',
      (event) => {
        if (event.detail.page === state.pageId) {
          render();
          checkoutUtils.displayActivePage(state.pageId);
        }
      },
      { passive: true }
    )
  ;

  // Re-render whenever the cart is re-fetched as we may have updated data
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:fetched',
      render,
      { passive: true }
    )
  ;

  const cardEmbeddedIframTimer = setInterval(
    () => {
      const brokenSpans = state
        .globalState
        .checkoutContainer
        .querySelectorAll('span#ppcp-credit-card-gateway-card-number[style],span#ppcp-credit-card-gateway-card-expiry[style],span#ppcp-credit-card-gateway-card-cvc[style]')
      ;

      if (!brokenSpans.length) {
        return;
      }

      //console.log(` -- removing styles from ${brokenSpans.length} <span> tags around credit card iframes.`);
      brokenSpans.forEach((brokenSpan) => {
        brokenSpan.removeAttribute('style');
      });
    },
    1000
  );
}

function mapTrueForm()
{
  checkoutUtils.mapFakeFormToTrueForm(
    state.template.querySelector('[co-fake-form]'),
    state.globalState.trueForm
  );
}

function render() {
  //console.log(`Rendering #${state.pageId}`);

  // hide the step 6 content, and show the step 5
  state.template.classList.add('checkout-step-5');
  state.template.classList.remove('checkout-step-6');

  renderIntro();
  renderFormData();
  mapTrueForm();
  addFormEventListeners();
  showCardForm();
  utils.dispatchGlobal('elp:cart:rendered');

}

function addEventListeners() {
  // Add handlers to go to the next page
  state.template
    .querySelectorAll('.step-5 [checkout-next]')
    .forEach(
      (nextBtn) => {
        nextBtn.addEventListener(
          'click',
          onFormSubmit
        );
      }
    )
  ;
}

function addFormEventListeners()
{
  state.template
    .querySelectorAll('[name="payment_method"]')
    .forEach(
      (radioButton) => {
        radioButton
          .addEventListener(
            'change',
            (event) => {
              //console.log('setting', event.target.value);
              utils.setSession('payment_method', event.target.value);
              showCardForm();
            },
            { passive: true }
          )
        ;
      }
    )
  ;
}

function showCardForm()
{

  const cardForm = state
    .template
    .querySelector('.payment_method_ppcp-credit-card-gateway .payment_box')
  ;

  const payPalButton = document.querySelector('#ppc-button');
  const creditCardButton = document.querySelector('.checkout-step-5 .co-footer__big-button');
  const cardDiv =  document.querySelector('.payment_method_ppcp-credit-card-gateway');
  const radioCreditCard =  document.querySelector('.ppcp-credit-card-gateway-radio');
  const radioPaypal =  document.querySelector('.ppcp-gateway-radio');

  if(!cardForm) {
    return;
  }

  let disableFormFields;
  let radioButton;

	//console.log(utils.getSession('payment_method'));
	//console.log(radioPaypal);
	//console.log(radioCreditCard);

  /* switch (utils.getSession('payment_method')) {
    case 'ppcp-gateway':
      //console.log(' -- hiding paypal form');
      cardForm.classList.remove('show');
	    payPalButton.style.display = 'block';
	    cardDiv.style.display = 'none';
      creditCardButton.style.display = 'none';
	    radioPaypal.classList.add('show');
	    radioCreditCard.classList.remove('show');
      break;

    case 'ppcp-credit-card-gateway':
    default:
      //console.log(' -- showing card form');
      cardForm.classList.add('show');
	    payPalButton.style.display = 'none';
	    cardForm.style.display = 'block';
	    cardDiv.style.display = 'block';
	    radioCreditCard.classList.add('show');
	    radioPaypal.classList.remove('show');
      creditCardButton.style.display = 'block';
      break;
  } */

  // Enable / disable the card for fields
  state.template
    .querySelectorAll('[checkout-card-form] input')
    .forEach(
      (cardDataInput) => {
        cardDataInput.disabled = disableFormFields;
      }
    )
  ;
}

function renderIntro() {
  // "Step [x]"
  state
    .template
    .querySelector('[co-current-checkout-step]')
    .innerText = checkoutUtils.fetchCurrentPageNumber(state.pageId, state.globalState.data)
  ;

  // "of [y]"
  state
    .template
    .querySelector('[co-total-checkout-steps]')
    .innerText = checkoutUtils.totalNumberOfCheckoutSteps(state.globalState.data)
  ;
}

function renderFormData() {
  // Set the payment method correctly
  const paymentMethod = utils.getSession('payment_method');
  state.template
    .querySelector(`[value="${paymentMethod}"]`)
    .checked = true
  ;
}

function onFormSubmit(event) {
  event.preventDefault();

  const formElement = state.template.querySelector('form');
  checkoutUtils.removeFormFieldInvalidations(formElement);

  const isValid = formElement.reportValidity();

  if (isValid) {
    // TODO: for now we just go to the next page. Gah.
    state.globalState.paymentData = checkoutUtils.formDataToObject(new FormData(formElement));

    utils.dispatchGlobal('elp:cart:changed');
    utils.dispatchGlobal(
      'elp:cart:goto-page',
      {
        page: checkoutUtils.fetchNextPageId(state.pageId, state.globalState.data)
      }
    );
  }
}
