'use strict';
/**
 * Step 6: Review
 */

import * as cartApi from "./checkout-cart-api.js";
import * as checkoutUtils from "./checkout-utils.js";
import * as nav from "./checkout-navigation.js";
import * as utils from './utils.js';
import * as oaUtils from "./open-access-utils.js";

// Import other steps so we can render attendees etc
import * as step0 from "./checkout-step-0.js";    // Cart
import * as step3 from "./checkout-step-3.js";    // Attendees

let state = {
  pageId: 6,
  summarySectionSelector: '[elp-template="checkout-step-6__section"]'
}

export function init(globalState) {
  state.globalState = globalState;
  state.template = globalState.checkoutContainer.querySelector('[co-step="5"]');
  state.summaryBody = state.template.querySelector('[co-summary-container]');

  // listen for requests to change to this page
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:goto-page',
      (event) => {
        if (event.detail.page === state.pageId) {
          render();
          checkoutUtils.displayActivePage(state.pageId);
        }
      },
      { passive: true }
    )
  ;

  // Re-render whenever the cart is re-fetched as we may have updated data
  document
    .querySelector('body')
    .addEventListener(
      'elp:cart:fetched',
      render,
      { passive: true }
    )
  ;

  // Add handlers to go to the next page
  /*
  state.template
    .querySelectorAll('.step-6 [checkout-next]')
    .forEach(
      (nextBtn) => {
        nextBtn.addEventListener(
          'click',
          (event) => {
            //state.globalState.trueForm.submit();
            state.globalState.trueForm.querySelector('#place_order').click();
          }
        );
      }
    )
  ;*/
}

function render() {
  //console.log(`Rendering #${state.pageId}`);

  state.template.classList.remove('checkout-step-5');
  state.template.classList.add('checkout-step-6');

  utils.emptyDomElement(state.summaryBody);

  renderBiller();
  renderAttendees();
  renderShipping();
  renderPayment();
  renderCartSummary();
  renderTerms();
  //renderTotals();
  //renderTrueForm();
  utils.dispatchGlobal('elp:cart:rendered');
}

function renderSection(title, body)
{
  const template = utils.cloneTemplate(state.summarySectionSelector);

  // Set the title
  template.querySelector('[co-summary-title]').innerText = title;

  // Add the body
  if (body) {
    template.querySelector('[co-summary-section-body]').appendChild(body);
  }

  return template;
}

function renderBiller()
{
  const sectionBody = document.createElement('p');
  sectionBody.innerHTML = checkoutUtils.buildBillingAddressHtml(state.globalState.trueForm);

  state.summaryBody.appendChild(
    renderSection('Billing details', sectionBody)
  );
}

function renderAttendees()
{
  if (!checkoutUtils.basketRequiresAttendeeData()) {
    return;
  }

  const domElement = step3.fetchAttendeeHtml();

  // Remove the ability to edit the attendee
  domElement
    .querySelectorAll('[co-attendee-edit-toggle]')
    .forEach( toggleElement => toggleElement.remove() )
  ;

  state.summaryBody.appendChild(
    renderSection('Attendees', domElement)
  );
}

function renderShipping()
{
  if (!state.globalState.needs_shipping) {
    return;
  }

  const sectionBody = document.createElement('p');

  if (utils.get('ship_to_different_address')) {
    sectionBody.innerHTML = checkoutUtils.buildShippingAddressHtml();
  } else {
    sectionBody.innerHTML = 'Ship to billing address:<br />' + checkoutUtils.buildBillingAddressHtml();
  }

  state.summaryBody.appendChild(
    renderSection('Billing details', sectionBody)
  );
}

function renderPayment()
{
  if (!('paymentData' in state.globalState)) {
    return;
  }

  const sectionBody = document.createElement('p');

  switch (utils.getSession('payment_method')) {
    case 'ppcp-credit-card-gateway':
      /*
      const cardNumber = document
        .querySelector('#ppcp-credit-card-gateway-card-number')
        .value
      ;

      const expiryDate = document
        .querySelector('#ppcp-credit-card-gateway-card-expiry')
        .value
      ;

      const cardString =
        '*'.repeat(cardNumber.length - 4)
        + cardNumber.substr(-4)
      ;

      sectionBody.innerHTML = `${cardString}<br />EXP: ${expiryDate}`;
      */
      sectionBody.innerText = 'Credit / Debit card';
      break;

    case 'ppcp-gateway':
      sectionBody.innerText = 'PayPal';
      break;
  }

  state.summaryBody.appendChild(
    renderSection('Payment details', sectionBody)
  );
}

function renderCartSummary()
{
  const cartSummary = step0.fetchCartHtml();

  // Remove the quantity selectors
  cartSummary
    .querySelectorAll('.qty')
    .forEach(
      (qtySelector) => {
        qtySelector.remove();
      }
    )
  ;

  state.summaryBody.appendChild(
    renderSection('Order summary', cartSummary)
  );
}

function renderTerms()
{
  const openAccessCompetenceCheckbox = state.template.querySelector('[elp-display-if-open-access]');

  // If there is no OA, disable and hide the checkbox and return
  if (!checkoutUtils.basketContainsOpenAccess()) {
    openAccessCompetenceCheckbox.classList.add('hidden');
    openAccessCompetenceCheckbox.querySelector('input').disabled = true;
    return;
  }

  // If there _is_ OA, make the checkbox compulory and show it
  openAccessCompetenceCheckbox.classList.remove('hidden');
  openAccessCompetenceCheckbox.querySelector('input').disabled = false;

  // hide all 3 separate competency statements
  openAccessCompetenceCheckbox
    .querySelectorAll('[elp-required-competency]')
    .forEach( competencyElement => competencyElement.classList.add('hidden') )
  ;

  // now unhide the ones that are needed for this customer
  oaUtils
    .fetchAllOpenAccessTypesInCart()
    .forEach(
      (openAccessType) => {
        openAccessCompetenceCheckbox
          .querySelector(`[elp-required-competency-${openAccessType.toLowerCase()}]`)
          .classList
          .remove('hidden')
        ;
      }
    )
  ;
}

function renderTotals()
{
  const totalsHtml = step0.fetchTotalsHtml();
  const cartSummaryElement = state.template.querySelector('[co-cart-summary]');
  utils.emptyDomElement(cartSummaryElement);
  cartSummaryElement.appendChild(totalsHtml);
}

function renderTrueForm() {
  if (
    !state.template.querySelector('[co-true-checkout-form]')
    && state.globalState.trueForm
  ) {
    //console.log(' ADDING THE TRUEFORM HERE');
    state
      .template
      .querySelector('[co-body]')
      .appendChild( state.globalState.trueForm )
    ;
  }
}
